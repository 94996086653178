import { template as template_8c8d6ee381004342af7b6e2d656e5787 } from "@ember/template-compiler";
const FKText = template_8c8d6ee381004342af7b6e2d656e5787(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
