import { template as template_7de0c372334649b89ab247b3371f6025 } from "@ember/template-compiler";
const FKLabel = template_7de0c372334649b89ab247b3371f6025(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
