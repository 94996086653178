import { template as template_94f1663356184c2584dfe71315f6f240 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_94f1663356184c2584dfe71315f6f240(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
