import { template as template_1620b73a70bd4acea0af6846aeecdca9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1620b73a70bd4acea0af6846aeecdca9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
